.search-wrapper {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem;

    img {
        max-height: 2.5rem;
    }

    form {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 50%;
        max-width: 30rem;
        height: 2.5rem;
        padding: 0 0.5rem;
        background-color: white;
        border: 1px solid $dark;
        border-radius: 0.25rem;

        &:focus-within {
            border-color: $secondary;
        }

        input, button {
            margin: 0;
            border: none;
            background: transparent;
        }

        input[type="text"] {
            width: 100%;
            padding: 0.5rem 0.75rem;
            outline: none;
        }
        
        input[type="reset"] {
            margin: 0;
            padding: 0;
            color: $secondary;
            font-size: 2.5rem;
            line-height: 1;
            cursor: pointer;
        }
    }
}

.search-results {
    list-style-type: none;
    position: absolute;
    top: calc(100% + 0.25rem);
    left: 0.25rem;
    display: block;
    width: calc(100% - 0.5rem);
    max-height: 8rem;
    margin: 0;
    padding: 0;
    background-color: white;
    overflow: auto;

    li {
        padding: 0.35rem 0.5rem;
        border-radius: 0.25rem;
        cursor: pointer;

        h3, p {
            margin: 0;
            font-size: 1rem;
        }

        &:hover {
            background: #f7f7f7;
        }

        &:not(:first-child) {
            border-top: 1px solid #f7f7f7;
        }
    }
}

@media (max-width: 649px) {
    .gmnoprint {
        display: none;
    }

    .search-wrapper {
        form {
            width: 100%;
        }

        > img {
            display: none;
        }
    }
}