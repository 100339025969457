#status-filter {
    padding: 1.5rem 0;
    border-bottom: 1px solid $dark;

    label.color-block {
        position: relative;
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin: 0.25rem 0;
        cursor: pointer;
    }

    input[type="checkbox"] {
        position: absolute;
        width: 0;
        height: 0;
        opacity: 0;

        &:not(:checked) + .checkbox {
            background-color: transparent !important;
        }
    }

    .checkbox {
        width: 1rem;
        height: 1rem;
        border: 2px solid black;
    }

    .status-indicators {
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        
        h3 {
            margin: 0;
        }
        
        > div {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0.25rem;
        }
        
        .arrow {
            margin-left: 0.5rem;
            transform: rotate(-90deg);
            transition: transform 0.25s ease-in-out;
        }
    }
    
    form {
        max-height: 0;
        margin: 0 auto;
        padding: 0 0.5rem;
        overflow: hidden;
        transition: all 0.25s ease-in-out;
    }

    &.expanded {
        .status-indicators {
            .arrow {
                transform: rotate(90deg);
            }
        }

        form {
            max-height: 6rem;
            margin-top: 0.5rem;
        }
    }

    .indicator {
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
    }
}