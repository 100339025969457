#map-container {
    flex-grow: 1;
    display: grid;
    grid-template-columns: 0 1fr;
    grid-template-rows: max-content 1fr;
    align-items: stretch;
    height: 100%;

    @media (min-width: 650px) {
        &.expanded {
            grid-template-columns: min-content 1fr;

            aside {
                width: 30vw;
                min-width: 320px;
                max-width: 400px;
                padding-left: 2rem;
                padding-right: 2rem;
            }
        }
    }

    aside {
        width: 0;
        height: 100%;
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
        overflow-y: auto;
        transform-origin: left;
    }

    .toggle-wrapper {
        display: none;
        isolation: isolate;
        z-index: 1;

        @media (min-width: 650px) {
            display: block;
            position: absolute;
            left: 0;
            top: calc(50vh - 2rem);
        }

        .expand-drawer {
            margin: 0;
            padding: 0.5rem 0.6rem 0.7rem 0.5rem;
            background-color: white;
            border: none;
            border-radius: 0 0.25rem 0.25rem 0;
            box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
            font-size: 20px;
            font-weight: 700;
            outline: none;

            img {
                transform: rotate(180deg);
            }

            &.expanded {
                img {
                    transform: rotate(0);
                }
            }
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            transform: translateY(calc(-50vh + 2rem));
            display: block;
            width: 0.25rem;
            height: 100vh;
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.15),
                transparent
            );
            z-index: -1;
        }
    }

    @media (max-width: 649px) {
        grid-template-columns: 1fr;

        aside,
        > div {
            grid-column: 1;
            grid-row: 2;
        }

        aside {
            width: 100%;
            max-width: none;
            padding-top: 0;
            padding-left: 2rem;
            padding-right: 2rem;
            background-color: white;

            &.hidden {
                z-index: -1;
            }
        }
    }
}
