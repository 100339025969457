#sort-options {
    padding: 0 0 1.5rem;
    border-bottom: 1px solid $dark;

    h3 {
        margin-bottom: 0.5rem;

        @media (max-width: 649px) {
            margin-top: 0;
        }
    }

    > div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 0.5rem;
    }

    button {
        margin: 0;
        padding: 0.35rem 1rem;
        border: 1px solid $dark;
        background-color: transparent;
        border-radius: 0.25rem;
        color: $dark;
        font-weight: 500;
        transition: all 0.2s ease-in-out;

        &:disabled {
            background-color: $primary;
            border-color: $primary;
            color: white;
        }
    }
}
