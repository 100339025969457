#locations {
    list-style-type: none;
    margin: 0;
    padding: 0.5rem 0;

    li {
        display: grid;
        grid-template-columns: 1fr max-content;
        margin: 1.5rem 0;

        &:has(button:hover) {
            h2 {
                color: $secondary;
            }
        }

        h2, p {
            grid-column: 1;
        }
        
        p {
            margin: 0;
            font-size: 12px;
        }

        button {
            align-self: start;
            grid-column: 2;
            grid-row: 1 / 3;
            margin: 0;
            padding: 0;
            background: transparent;
            border: none;
            font-size: 12px;
            outline: none;

            img {
                display: block;
                margin: 0 auto 0.25rem;
                padding: 0.25rem;
                border: 1px solid $primary;
                border-radius: 50%;
                transition: border-color 0.25s ease-in-out;
            }

            span {
                color: $primary;
                transition: color 0.25s ease-in-out;
            }

            &:focus-within, &:hover {
                img {
                    border-color: $secondary;
                }

                span {
                    color: $secondary;
                }
            }
        }
    }
    
    p span {
        display: block;
    }
}