:root {
    --primary: #44204d;
    --secondary: #990066;
    --dark: #4b4b49;

    --status-completed: #981869;
    --status-completed-alt: #520034;
    --status-in-legals: #186998;
    --status-in-legals-alt: #003c5e;
    --status-pipeline: #ed9b1f;
    --status-pipeline-alt: #965b00;
    --status-targets: #e28aff;
    --status-targets-alt: #d863ff;
}

$primary: var(--primary);
$secondary: var(--secondary);
$dark: var(--dark);

$status-completed: var(--status-completed);
$status-completed-alt: var(--status-completed-alt);
$status-in-legals: var(--status-in-legals);
$status-in-legals-alt: var(--status-in-legals-alt);
$status-pipeline: var(--status-pipeline);
$status-pipeline-alt: var(--status-pipeline-alt);
$status-targets: var(--status-targets);
$status-targets-alt: var(--status-targets-alt);

body {
    color: $dark;
    font-family: "Raleway";
    font-size: 15px;
    line-height: 1.25;
}

* {
    box-sizing: border-box;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
}

button {
    cursor: pointer;
}

.button-primary {
    padding: 0.5rem 1rem;
    background-color: $secondary;
    color: white;
    border: none;
    border-radius: 0.5rem;
    font-weight: 700;
    cursor: pointer;
    transition: background-color 0.25s ease-in-out;

    &:hover {
        background-color: $primary;
    }
}

h2 {
    margin-top: 0;
    color: $primary;
    font-size: 17px;
    font-weight: 500;
    transition: color 0.25s ease-in-out;
}
