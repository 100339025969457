.login {
    display: grid;
    grid-template-rows: 1fr min-content;
    align-items: center;
    gap: 2rem;
    height: 100vh;

    .button-wrapper {
        text-align: center;

        img {
            display: block;
            max-width: 10rem;
            margin: 0 auto;
        }

        button,
        a {
            display: inline-block;
            margin-top: 2rem;
            padding: 0.5rem 1rem;
            background-color: $secondary;
            color: white;
            border: none;
            border-radius: 0.5rem;
            font-size: 18px;
            font-weight: 700;
            text-decoration: none;
            cursor: pointer;
            transition: background-color 0.25s ease-in-out;

            &:hover {
                background-color: $primary;
            }
        }
    }

    .disclaimer {
        margin-top: 2rem;
    }

    .credit {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        padding: 2rem;
        color: $dark;
        font-size: 12px;
        text-align: center;
        text-decoration: none;
    }
}

.spinner {
    display: grid;
    place-items: center;
    height: 100vh;
    height: 100dvh;
}