#totals {
    position: absolute;
    bottom: 1.5rem;
    left: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    min-width: 30%;
    padding: 1rem clamp(1rem, 5%, 2rem);
    background-color: white;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;

    > p {
        color: $primary;
        font-weight: 700;
    }

    p {
        margin: 0;
    }

    .values {
        text-align: right;
    }

    @media (max-width: 649px) {
        bottom: 0;
        left: 0;
        width: 100%;
    }
}