article {
    margin-bottom: 2rem;
    padding: 1rem 0;
    line-height: 1.5;

    p {
        span {
            display: block;
        }
    }

    .close-button {
        float: right;
        display: block;
        margin: 0.5rem 0 0;
        padding: 0;
        background: transparent;
        border: none;
        color: $secondary;
        font-size: 3rem;
        font-weight: 400;
        line-height: 0;
        outline: none;
        transition: color 0.25s ease-in-out;

        &:focus-within, &:hover {
            color: $primary;
        }
    }

    @media (max-width: 649px) {
        padding-top: 0;

        .close-button {
            display: none;
        }
    }
}