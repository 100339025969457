header {
    grid-column: 1 / -1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2rem;

    img {
        max-width: 6rem;
    }

    button {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        margin: 0;
        padding: 0;
        color: $dark;
        background: transparent;
        border: none;
        white-space: nowrap;

        img {
            width: 1.25rem;
        }
    }

    .close-button {
        display: block;
        margin: 0;
        padding: 0;
        background: transparent;
        border: none;
        color: $secondary;
        font-size: 3rem;
        font-weight: 400;
        line-height: 0;
        outline: none;
        transition: color 0.25s ease-in-out;

        &:focus-within,
        &:hover {
            color: $primary;
        }
    }

    @media (min-width: 650px) {
        height: 0;
        padding: 0;
        overflow: hidden;

        > * {
            display: none !important;
        }
    }
}
