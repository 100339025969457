aside {
    position: relative;
    padding: 1rem 2rem;
    transition: max-width 0.25s ease-in-out;
    z-index: 2;

    h3 {
        font-size: 1rem;
        font-weight: 400;
    }
}
