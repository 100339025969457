@import './assets/scss/global';
@import './assets/scss/views/login';
@import './assets/scss/views/map';
@import './assets/scss/components/header';
@import './assets/scss/components/sidebar';
@import './assets/scss/components/sort';
@import './assets/scss/components/status';
@import './assets/scss/components/list';
@import './assets/scss/components/single';
@import './assets/scss/components/googlemap';
@import './assets/scss/components/totals';

#map {
    width: 100%;
    height: 100%;
}